@import "@urbanpiper-engineering/meraki-components/dist/main.css";

html,
body {
  background: #f9f9f9;
}

* {
  margin: 0;
  padding: 0;
}

.container {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  min-height: 80vh;
  margin-top: 2px;
  @media screen and (min-width: 768px) {
    padding-top: 40px;
    width: 90vw;
  }
}

//normalize scroll for all browsers and operating systems

// for chrome, edge, safari
::-webkit-scrollbar {
  width: 2px; //vertical scrollbar width
  height: 2px; //horizontal scrollbar height
}

::-webkit-scrollbar-track {
  background-color: white;
}

::-webkit-scrollbar-thumb {
  background-color: #7f7f7f;
  border-radius: 5px;
}

// for firefox
@supports (scrollbar-width: thin) {
  * {
    scrollbar-width: thin;
    scrollbar-color: #7f7f7f;
  }
}
