.layout {
  position: relative;
  .layout__children {
    min-height: 60vh;
    width: 100%;
  }
}

.layoutDisabled {
  pointer-events: none;
}

.customizeThemeBar {
  width: 100vw;
  background-color: white;
}
